import axios from 'axios';

export const axiosConfigInstance = (path: string,token?:string,type?:any) => axios.create({
    baseURL: `https://music.dapi.subox.co.kr${path}`,
    // baseURL: `http://192.168.11.5:10125${path}`,
    timeout: 40000,
    headers: {
        'authorization':token,
        ...type
    }
});

export const axiosHome = (path: string,token?:string,type?:any) => axios.create({
    baseURL: `https://music.wapi.subox.co.kr${path === undefined ? '' : path}`,
    // baseURL: `http://192.168.1.22:10700${path}`,
    timeout: 40000,
    headers: {
        'authorization':token,
        ...type
    }
});

export const resultAlert = (result:boolean,txt:string,action?:any) =>{
    if(result){
        alert(`${txt} 되었습니다.`);
        if(action === undefined){
            window.location.reload();
        }else{
            action();
        }
    }else{
        alert('잠시후 다시 시도해주세요.');
    }
}


export const IMG_URI = '';
// export const IMG_URI = 'https://music.dapi.subox.co.kr';
